import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Input } from 'reactstrap'
import DatePicker from 'react-datepicker'
import './DateRangePicker.scss'

export const DateRangePicker = ({ onChangeDates, minDate = false, selectedStartDate = false, showTimeSelect }) => {
  const [startDate, setStartDate] = useState(moment().startOf('day')) // Today at 00:00:00
  const [endDate, setEndDate] = useState(moment().endOf('day')) // Today at 23:59:59
  const [startTime, setStartTime] = useState('00:00')
  const [endTime, setEndTime] = useState('23:59')

  useEffect(() => {
    if (selectedStartDate) setStartDate(selectedStartDate)
  }, [selectedStartDate])

  const handleChangeDate = (date, type) => {
    if (type === 'start') {
      setStartDate(date)
      onChangeDates({ start: date, end: endDate })
    } else {
      setEndDate(date)
      onChangeDates({ start: startDate, end: date })
    }
  }

  const formatTime = (value) => {
    value = value.replace(/[^0-9]/g, '').slice(0, 4)
    if (value.length <= 2) return value
    return `${value.slice(0, 2)}:${value.slice(2, 4)}`
  }

  const handleChangeTime = (e, type) => {
    const formattedTime = formatTime(e.target.value)
    type === 'start' ? setStartTime(formattedTime) : setEndTime(formattedTime)
  }

  const validateTime = (value) => {
    let hours = parseInt(value.slice(0, 2), 10)
    let minutes = parseInt(value.slice(2, 4), 10)
    if (hours > 23) hours = 23
    if (minutes > 59) minutes = 59
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  }

  const handleBlurTime = (e, type) => {
    const value = (type === 'start' ? startTime : endTime).replace(/[^0-9]/g, '')
    const newTime = value.length === 4 ? validateTime(value) : type === 'start' ? '00:00' : '23:59'

    if (type === 'start') setStartTime(newTime)
    if (type === 'end') setEndTime(newTime)

    const [finalHours, finalMinutes] = newTime.split(':')
    if (type === 'start') {
      const finalStart = startDate.set({ hour: finalHours || '00', minute: finalMinutes || '00', second: 0 })
      onChangeDates({ start: finalStart, end: endDate })
    } else {
      const finalEnd = endDate.set({ hour: finalHours || '23', minute: finalMinutes || '59', second: 0 })
      onChangeDates({ start: startDate, end: finalEnd })
    }
  }

  return (
    <div className='date-range-picker'>
      {showTimeSelect && <b className='from'>From</b>}
      <DatePicker
        selectsStart
        endDate={endDate}
        minDate={minDate}
        selected={startDate}
        startDate={startDate}
        dateFormat='MMM DD, YYYY'
        popperPlacement='bottom-start'
        calendarClassName='custom-calendar'
        filterDate={date => moment() > date}
        onChange={date => handleChangeDate(date, 'start')}
        className='date-input btn btn-secondary m-0 mr-2 my-xs-2'
      />
      {showTimeSelect && (
        <>
          <b className='start at'>at</b>
          <Input
            value={startTime}
            placeholder='00:00'
            onClick={() => setStartTime('')}
            className='time-input btn btn-secondary'
            onBlur={e => handleBlurTime(e, 'start')}
            onChange={e => handleChangeTime(e, 'start')}
          />
        </>
      )}
      {showTimeSelect ? <br /> : <span className='to'>to</span>}
      {showTimeSelect && <b className='to'>To</b>}
      <DatePicker
        selectsEnd
        endDate={endDate}
        selected={endDate}
        minDate={startDate}
        startDate={startDate}
        dateFormat='MMM DD, YYYY'
        popperPlacement='bottom-end'
        calendarClassName='custom-calendar'
        filterDate={date => moment().add(1, 'day') > date}
        onChange={date => handleChangeDate(date, 'end')}
        className='date-input btn btn-secondary m-0 ml-2 my-xs-2'
      />
      {showTimeSelect && (
        <>
          <b className='end at'>at</b>
          <Input
            value={endTime}
            placeholder='23:59'
            onClick={() => setEndTime('')}
            onBlur={e => handleBlurTime(e, 'end')}
            className='time-input btn btn-secondary'
            onChange={e => handleChangeTime(e, 'end')}
          />
        </>
      )}
    </div>
  )
}
