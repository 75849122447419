/**
 * Usage: <ErrorModal error={error} header='Error header' />
 */
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { ActionButton } from './ActionButton'
import { useStores } from '../stores/RootStore'
import { ANNOYING_HEADERS, REPLACE } from './ErrorAlert'
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

// eslint-disable-next-line
String.prototype.replaceArray = function (find, replace) {
  let replaceString = this
  for (let i = 0; i < find.length; i++) { replaceString = replaceString.replace(find[i], replace[i]) }
  return replaceString
}

export const ErrorModal = props => {
  const { header = 'Something went wrong', error, setErrorState } = props
  const [modal, setModal] = useState(true)
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { firstName, lastName, primaryEmail: email } = user || {}
  const name = `${firstName || ''} ${lastName || ''}`
  const errorText = (error?.message || error || 'Unknown error').toString().replaceArray(ANNOYING_HEADERS, REPLACE)

  const toggle = () => {
    setErrorState && setErrorState(!error)
    setModal(!modal)
  }

  useEffect(() => {
    const currentEvent = 'UI Error'
    const eventHistory = window?.analyticsEventHistory
    const newHistory = [currentEvent, ...eventHistory].slice(0, 10)
    window.analyticsEventHistory = newHistory
    if (window.analytics) {
      window.analytics.track('UI Error', {
        error: errorText,
        headerError: header,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[1], // special case using [1] instead of [0]
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      })
    }
    if (Sentry.isInitialized()) {
      Sentry.captureMessage(errorText)
    }
  }, [header, errorText])

  return (
    <Modal isOpen={error && modal} toggle={toggle} {...props}>
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>
        <Alert color='danger'>
          {errorText}
          <ActionButton
            color='link'
            className='text-primary'
            onClick={() => Sentry.showReportDialog({ user: { name, email } })}
          >
            Give us feedback.
          </ActionButton>
        </Alert>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='secondary' onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}
