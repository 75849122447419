import React, { useState } from 'react'
import { StepInstructionsInfo } from '..'
import { Form, Field } from 'react-final-form'
import { fieldModifiers } from '../modifiers'
import { AuthLinkButton } from '../../AuthLinkButton'
import { customAppStepInstructions } from './StepInstructions'
import { Row, FormGroup, Label, FormFeedback, Input, FormText, Col, Button } from 'reactstrap'
import {
  FaEye as ShowPass,
  FaEyeSlash as HidePass
} from 'react-icons/fa'

export const MivaCustomAuth = ({ props }) => {
  const { slug, instanceNumber, userInputNeeded } = props

  const renderFormContent = props => {
    const { handleSubmit, errors, values } = props
    const { store_url: storeURL, access_token: accessToken, signature, store_code: storeCode } = values || {}
    const shouldBeEnabled = Object.keys(errors).length === 0 && errors.constructor === Object

    const handleModifiers = (e, modifiers) => {
      const value = e.target.value
      const modifiedValue = (modifiers || []).map(i => fieldModifiers[i](value))
      return (modifiedValue || [])[0]
    }

    return (

      <Row className='mt-4'>
        <form className='max-80 w-100' onSubmit={handleSubmit}>
          {(userInputNeeded || []).map(item => {
            const { name, label, required, modifiers, hint, type } = item
            const [showPass, setShowPass] = useState(false)
            const showEye = type === 'password'
            return (
              <Row key={name}>
                <Col xs={showEye ? 10 : 12}>
                  <FormGroup>
                    <div className='form-label-group'>
                      <Field
                        name={name}
                        validate={required ? e => e ? undefined : `${label} is required` : null}
                      >
                        {({ input, meta }) => (
                          <>
                            <Input
                              id={name}
                              {...input}
                              type={showPass ? 'text' : 'password'}
                              onChange={e => (modifiers || []).length > 0
                                ? input.onChange(handleModifiers(e, modifiers))
                                : input.onChange(e)}
                              placeholder={label}
                              invalid={meta.touched && meta.invalid}
                            />
                            <Label for={name}>{label}</Label>
                            {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                          </>
                        )}
                      </Field>
                      {hint && <FormText>{hint}</FormText>}
                    </div>
                  </FormGroup>
                </Col>
                {showEye &&
                  <Col xs={2}>
                    <Button size='lg' color='secondary' className='w-100' onClick={() => setShowPass(!showPass)}>
                      <Row noGutters>
                        <Col>
                          {showPass ? <HidePass /> : <ShowPass />}
                        </Col>
                        <Col className='d-none d-lg-block'>
                          {showPass ? 'Hide' : 'Show'}
                        </Col>
                      </Row>
                    </Button>
                  </Col>}
              </Row>
            )
          })}

          <div className='d-flex justify-content-end mt-4'>
            <AuthLinkButton
              text='Authorize Miva'
              disabled={!shouldBeEnabled}
              href={`/auth/${slug}/${instanceNumber}?store_url=${storeURL}&access_token=${accessToken}&signature=${signature}&store_code=${storeCode}`}
            />
          </div>
        </form>
      </Row>
    )
  }

  return (
    <>
      <StepInstructionsInfo stepInstructions={customAppStepInstructions} />
      <Form onSubmit={() => { }} render={renderFormContent} />
    </>
  )
}
